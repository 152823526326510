import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {MessageService} from 'primeng/api';
import {PrimeNGConfig} from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css'],
  providers: [MessageService]
})
export class UserEditComponent implements OnInit {

 /* private GetAllBenutzerApi = 'http://localhost/ApiGetaway/GetAllBenutzer.php';
  private GetSingleUserApi =  'http://localhost/ApiGetaway/GetSingleUser.php';
  private UpdateSingleUserApi = 'http://localhost/ApiGetaway/UpdateUserInfo.php';
  private DeleteSingleBenutzerApi = 'http://localhost/ApiGetaway/DeleteSingleBenutzer.php'; */

  private GetAllBenutzerApi = '/ApiGetaway/GetAllBenutzer.php';
  private GetSingleUserApi =  '/ApiGetaway/GetSingleUser.php';
  private UpdateSingleUserApi = '/ApiGetaway/UpdateUserInfo.php';
  private DeleteSingleBenutzerApi = '/ApiGetaway/DeleteSingleBenutzer.php';


  public UserInfos: any;
  public GetSingleUser: any;

  constructor
  (
    private http: HttpClient,
    private messageService: MessageService,
    private primeConfig: PrimeNGConfig,
    private datePipe: DatePipe
  )
  { }

  ngOnInit(): void {
    // GET ALL USER LIST AND SHOW IN THE TABLE
    this.http.get(this.GetAllBenutzerApi).subscribe(async data =>{
      const res = JSON.parse(JSON.stringify(data));
      this.UserInfos = res;
    });
  }

  // GET SINGLE DETAILS AND SHOW
 getsSingleUser(id: any){
    this.http.post(this.GetSingleUserApi, id).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      this.GetSingleUser = res;
    });

  }

  // UPDATE SINGLE USER
  onSubmit(f: NgForm) {
  //  f.value['datum'] = this.datePipe.transform(f.value['datum'], 'dd-MM-yyyy');
   // console.log(f.value);
    this.http.post(this.UpdateSingleUserApi, f.value).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));

      if (res['status'] === 'erfolglisch') {
        this.ShowSuccess();
        f.resetForm();
        this.ngOnInit();
      } else {
        this.ShowError();
      }
    })
  }

// PRIMENG SUCCESS TOAST
  ShowSuccess(){
    this.messageService.add({
      severity: 'success',
      summary: 'Erfolgreich',
      detail: 'Die Kundeninformationen wurden erfolgreich aktualisiert'
    });
  }

  // PRIMENG ERROR TOAST
  ShowError(){
    this.messageService.add({
      severity: 'error',
      summary: 'Fehler',
      detail: 'Bitte füllen Sie alle erforderlichen Felder aus'
    });
  }

  // DELETE A SINGLE USER
  DeleteSingleUser(id: any) {
    this.http.post(this.DeleteSingleBenutzerApi, id).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      console.log(res);
      this.ngOnInit();
    });
  }

  // PRIMENG DELETE SUCCESS TOAST
  ShowDeleteSuccess(){
    this.messageService.add({
      severity: 'warn',
      summary: 'Gelöscht',
      detail: 'Der Kunde wurde erfolgreich gelöscht'
    })
  }

}
