import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, LOCALE_ID, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { Kunden } from 'src/app/classes/kunden';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm, NgControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {PrimeNGConfig} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import { DatePipe } from '@angular/common';
import {AbstractControl, ControlValueAccessor} from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { MatDialog } from '@angular/material/dialog';
import { AddklisteComponent } from 'src/app/components/addkliste/addkliste.component';
import { AddstatusComponent } from 'src/app/components/addstatus/addstatus.component';
import { HidedataComponent } from 'src/app/components/hidedata/hidedata.component';
import { combineLatest, of, Subject } from 'rxjs';
import { UserTableComponent } from 'src/app/components/user-table/user-table.component';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [MessageService, DatePipe, ReactiveFormsModule],

})

export class DashboardComponent implements OnInit  {
  //GET, POST API's Requests.
 /* private GetAllDatasApi = 'http://localhost/ApiGetaway/GetAllDetails.php';
  private GetAllKundenApi = 'http://localhost/ApiGetaway/GetAllKunden.php';
  private PostCustomersApi = 'http://localhost/ApiGetaway/PostCustomers.php';
  private PostUsersApi = 'http://localhost/ApiGetaway/PostUsers.php';
  private PostCustomersStatusApi = 'http://localhost/ApiGetaway/PostCustomersStatus.php';
  private GetCustomersIdsApi = 'http://localhost/ApiGetaway/GetCustomersIds.php';
  private PostNotesApi = 'http://localhost/ApiGetaway/PostNotes.php';
  private HideStatusApi = 'http://localhost/ApiGetaway/UpdateHideStatus.php';
  private GetAllStatusListeApi = 'http://localhost/ApiGetaway/GetAllStatusList.php';
  private GetCustomerNameApi = 'http://localhost/ApiGetaway/GetCustomerName.php';
  private GetVorgAutomaticApi = 'http://localhost/ApiGetaway/GetVorgAutomatic.php';
  private PostAutomateEmailApi = 'http://localhost/ApiGetaway/PostAutomateEmail.php';
  private PostAutomateEmailStatusApi = 'http://localhost/ApiGetaway/PostAutomateEmailStattus.php';

*/
    //GET, POST API's Requests.
    private GetAllDatasApi = '/ApiGetaway/GetAllDetails.php';
    private GetAllKundenApi = '/ApiGetaway/GetAllKunden.php';
    private PostCustomersApi = '/ApiGetaway/PostCustomers.php';
    private PostUsersApi = '/ApiGetaway/PostUsers.php';
    private PostCustomersStatusApi = '/ApiGetaway/PostCustomersStatus.php';
    private GetCustomersIdsApi = '/ApiGetaway/GetCustomersIds.php';
    private PostNotesApi = '/ApiGetaway/PostNotes.php';
    private HideStatusApi = '/ApiGetaway/UpdateHideStatus.php';
    private GetAllStatusListeApi = '/ApiGetaway/GetAllStatusList.php';
    private GetCustomerNameApi = '/ApiGetaway/GetCustomerName.php';
    private GetVorgAutomaticApi = '/ApiGetaway/GetVorgAutomatic.php';
    private PostAutomateEmailApi = '/ApiGetaway/PostAutomateEmail.php';
    private PostAutomateEmailStatusApi = '/ApiGetaway/PostAutomateEmailStattus.php';





  //Variables and Arrays.
  public ShowDatas : any = {};
  public ShowKunden : any;
  public genPin: any;
  public SumDatas : any = {};
  public UserNumId : any;
  public KundenIds: any;
  public CustomerStatus: any = {};
  public AllStatus: any;
  public statt: any;



  //Kunde Form.
  @ViewChild('kunde') kunde: any;
  @ViewChild('datum') datum: any;
  @ViewChild('kunden') kunden : any;
  @ViewChild('bemerkung') bemerkung : any;
  @ViewChild('mitarbeiter') mitarbeiter: any;
  @ViewChild('notizen') notizen: any
  public kundenId : any;
  public TagesPlanHin: any = {};

  //Benutzer Form.
  @ViewChild('kunde_bnz') kunde_bnz: any;
  @ViewChild('datum_bnz') datum_bnz: any;
  @ViewChild('vorname') vorname: any;
  @ViewChild('nachname') nachname: any;
  @ViewChild('email') email: any;
  @ViewChild('tel') tel: any;
  @ViewChild('pin') pin: any;
  @ViewChild('kundenummer_bnz') kundenummer_bnz: any;
  @ViewChild('bemerkung_bnz') bemerkung_bnz: any;


  //Status Form.
  @ViewChild('kundenummmer_bnzh') kundenummer_bnzh: any;
  @ViewChild('datum_bnzh') datum_bnzh: any;
  @ViewChild('status_bnzh') status_bnzh: any;
  @ViewChild('info_bnzh') info_bnzh: any;


  //Notzien Form
  @ViewChild('title_not') title_not: any;
  @ViewChild('datum_not') datum_not: any;
  @ViewChild('info_not') info_not: any;

  @ViewChild('knLabel') knLabel: any;


  //PrimeNg Table Test
  tableData : any;
  public showData: any = {};
  dateFormat = 'dd.MM.yyyy';
  locale = 'de_DE';
  searchResult: any;
  search: any;

  public Mydate = new Date().toLocaleDateString('de-DE');
  value: any;


  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private primeConfig: PrimeNGConfig,
    private datepipe: DatePipe,
    public dialog: MatDialog
    )
  {
    this.GetAllKunden();
    this.GetCustomersIds();
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);

  }



  ngOnInit(): void {
    this.primeConfig.ripple = true;
    //GET All Detas for Table.
    this.http.get(this.GetAllDatasApi).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
      this.showData = res;
     //console.log(this.showData);
      const kunden: Kunden[] = res;
      this.tableData = kunden;
      this.GetAllStatus();
    });

  }
  getEventValue($event:any) :string {
    return $event.target.value;
  }

  /*
  onSearch(event: any) {
    const value = event.target.value;
    this.searchResult = this.showData.filter((filterd: { Kunde: string | any[]; }) =>
      filterd.Kunde.includes(value));
      console.log(this.searchResult)
  }

  filterProducts(value: any) {


  }

  onSearchChange(searchValue: string): void {
    console.log(searchValue);
    if(searchValue.includes('test')) {
      console.log('1111');
    }
  }


  // On Enter Input Value
  onEnter(myEvent: any) {
    console.log('test', myEvent);
  }

  // On Keypress Input Value
  onKeypressEvent(event: any){
    //console.log(event.target.value);
  } */

  // GET Details from TagesPlan Table onLoad
  GetAllDatas(): void {
    this.http.get(this.GetAllDatasApi).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
      this.ShowDatas = res;
    });
  }

  //GET All Kunden and Push it into K.List.
  GetAllKunden(): void {
    this.http.get(this.GetAllKundenApi).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
      this.ShowKunden = res;
    });
  }

  //POST Customer Details into Database.
 KundenHinzufugen(f: NgForm): void {
   if (this.kunde === undefined || this.datum === undefined || this.kunden === undefined || this.bemerkung === undefined || this.mitarbeiter === undefined ) {
 //    console.log('Error Plase fill the form');
     this.ShowError();
   } else {

    var deDate = new Date(this.datum);
    let formatdate = deDate.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit'});

   // var datepipe = new DatePipe("de-DE");
   // let formatdate = datepipe.transform(this.datum, this.dateFormat, 'de-DE');
   // console.log(formatdate);

    const UserInputs = {
      kunde: this.kunde,
      datum: this.datum = formatdate,
      kunden: this.kunden,
      bemerkung: this.bemerkung,
      notizen: this.notizen,
      mitarbeiter: this.mitarbeiter
    }
   // console.log(UserInputs);
      this.TagesPlanHin = UserInputs;
      this.http.post(this.PostCustomersApi, this.TagesPlanHin).subscribe(async data => {
        const res = JSON.parse(JSON.stringify(data));
        this.showSuccess();
        if(this.kunde == 'Porsche Zentrum') {
          this.http.post(this.PostAutomateEmailApi, this.TagesPlanHin).subscribe(async datas => {
            const resp = JSON.parse(JSON.stringify(datas));
            console.log('resp :',resp);
          });
         }
        this.info_bnzh = this.kunden + '\n' + this.bemerkung + '\n' + this.notizen;
        if(this.kunde == 'Büro' || this.kunde == 'Stva' || this.kunde == 'Abmeldungen') {
          console.log('Not match!');
        } else {
        this.http.post(this.GetVorgAutomaticApi, this.kunde).subscribe(async data => {
        const response = JSON.parse(JSON.stringify(data));
        console.log('Accepted');
       // console.log('RES :',response);
        if(response.length == 0 ){
            console.log('Empty');
        }else {
          this.kundenummer_bnzh = response[0]['kundenummer'];
        }
        }) ;
        f.resetForm();
        this.ngOnInit();
      }
      });

   }
  }

  //Erase the Kunden Form.
  EraseCustomersForm(f: NgForm): void {
    f.resetForm()
  }

  // Add User "Benutzer" Into the Database.
  AddUser(d: NgForm): void{
    if (this.kunde_bnz === undefined || this.datum_bnz === undefined || this.vorname === undefined || this.nachname === undefined || this.tel === undefined) {
  //    console.log('Error Plase fill the form');
      this.ShowError();
    } else {
      this.genPin = '100' + this.GenaratePin(5);
      this.UserNumId = 'BNZ100U' + this.CustomerNumber(3);


      var datepipe = new DatePipe("de-DE");
      let formatdate = datepipe.transform(this.datum_bnz, 'dd.MM.yyyy', 'de-DE');
     // console.log(formatdate);

      const UserInputs = {
        kunde: this.kunde_bnz,
        datum: this.datum_bnz = formatdate,
        vorname: this.vorname,
        nachname: this.nachname,
        email: this.email,
        tel: this.tel,
        pin: this.genPin,
        kundenummer: this.UserNumId,
        bemerkung: this.bemerkung_bnz
      }
      console.log(UserInputs);
      this.ShowSuccessUser();
      this.http.post(this.PostUsersApi, UserInputs).subscribe(async data => {
        const res = JSON.parse(JSON.stringify(data));
      //  console.log(res);

      });
      this.kundenummer_bnzh = this.UserNumId;
  //    this.searchAfter(this.kundenummer_bnzh);
    }

  }

  //Search with Knd Nummer After Name
  searchAfter(kdSearch: any): void {
  console.log(kdSearch);
    this.http.post(this.GetCustomerNameApi, kdSearch).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
    //  console.log(res);
      const label: string = res[0]['vorname'] + ', ' + res[0]['nachname'];
      this.knLabel = label;
    })
  }


  searchAfterKD(Kn: any) {
    console.log(Kn);
    this.http.post(this.GetCustomerNameApi, Kn).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
      return res[0]['vorname'];
    })
  }

  //Erase "Benutzer" Form.
  UserFormErase(d: NgForm) {
    d.resetForm();
  }

  eraseForm(c: NgForm) {
    c.resetForm();
    const label: string = '';
    this.knLabel = label;
  }

  //GENERATE PIN Code for User .
  GenaratePin(length: any) {
    let result = [];
    let charachter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charachterLength = charachter.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(charachter.charAt(Math.floor(Math.random() * charachterLength)));
    }
    return result.join('');
  }

  //GENERATE Customer ID-Number for User.
  CustomerNumber(length: any) {
    let result = [];
    let charachter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charachterLength = charachter.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(charachter.charAt(Math.floor(Math.random() * charachterLength)));
    }
    return result.join('');
  }

  //GET Customers ID-Number from Database and Push it into Array.
  GetCustomersIds(): void {
    this.http.get(this.GetCustomersIdsApi).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      this.KundenIds = res;
    });

  }
  //POST User Status.
  PostCustomersDatas(): void {
    if (this.kundenummer_bnzh === undefined || this.datum_bnzh === undefined || this.status_bnzh === undefined || this.info_bnzh === undefined) {
      this.ShowError();
    } else {

      var datepipe = new DatePipe("de-DE");
      let formatdate = datepipe.transform(this.datum_bnzh, 'dd.MM.yyyy', 'de-DE');
    //  console.log(formatdate);

      const UserInputs = {
        kundenummer: this.kundenummer_bnzh,
        datum: this.datum_bnzh = formatdate,
        status: this.status_bnzh,
        info: this.info_bnzh
      }

      this.http.post(this.PostCustomersStatusApi, UserInputs).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
        //console.log('res:',res);
      this.showSuccess();
      this.http.post(this.GetCustomerNameApi, this.kundenummer_bnzh).subscribe(async datax => {
        const ress = JSON.parse(JSON.stringify(datax));
        const kd_name = ress[0]['vorname'];
        if (ress[0]['vorname'] == 'Porsche Zentrum') {
            this.http.post(this.PostAutomateEmailStatusApi, UserInputs).subscribe(async dataz => {
              const resback = JSON.parse(JSON.stringify(dataz));
              console.log(resback);
            })
        } else {
          console.log('false');
        }
      })

      });
    }

  }

  //POST Mitarbeiter Notes
  PostNotsInfo(): void {
    if (this.title_not === undefined || this.datum_not === undefined || this.info_not === undefined ) {
      this.ShowError();
    } else {
      var datepipe = new DatePipe("de-DE");
      let formatdate = datepipe.transform(this.datum_not, 'dd.MM.yyyy', 'de-DE');
      console.log(formatdate);
      const Notes = {
        title: this.title_not,
        datum: this.datum_not = formatdate,
        info: this.info_not
      }
      this.http.post(this.PostNotesApi, Notes).subscribe(async data => {
        const res = JSON.parse(JSON.stringify(data));
      //  console.log(res);
        this.showSuccess();
      });
    }
  }

  //Get All Status From Database and Put it in List
  GetAllStatus(): void {
    this.http.get(this.GetAllStatusListeApi).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
      this.AllStatus = res;
      //console.log(this.AllStatus);
    })
  }

  //Open Hide Data Table
  openHideDialog(): void {
    const dialogRef = this.dialog.open(HidedataComponent, {
      width: '1400px',
      data: {}
    });
  }

  //Open User Table
  openUserTabelDialog(): void {
    const dialogRef = this.dialog.open(UserTableComponent, {
      width: '1400px',
      data: {}
    });
  }

  //Open Add K in the List Popup
  openKDialog(): void {
    const dialogRef = this.dialog.open(AddklisteComponent, {
      width: '800px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.GetAllKunden();
      console.log('WebApp Restarted');
    })
  }


   //Open Add Status in the List Popup
   openSDialog(): void {
    const dialogRef = this.dialog.open(AddstatusComponent, {
      width: '500px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.GetAllStatus();
      console.log('WebApp Restarted');
    })
  }


  //Erase Status Form.
  StatusFormErase(c: NgForm){
    c.resetForm();
  }


  //PrimeNg Success Toast
  showSuccess() {
    this.messageService.add({
      severity:'success',
      summary: 'Erfolgreich',
      detail: 'Ihre Daten wurden erfolgreich registriert'
    });
  }

  //PrimeNg Error Toast
  ShowError(){
    this.messageService.add({
      severity: 'error',
      summary: 'Fehler',
      detail: 'Bitte füllen Sie alle erforderlichen Felder aus'
    });
  }

  //PrimeNg Success Toast for Add a User.
  ShowSuccessUser() {
    this.messageService.add({
      severity: 'success',
      summary: 'Erfolgreich',
      detail: 'Neuer Benutzer ist erfolgreich registriert'
    })
  }

  //Export Table Data As Excel
  ExportExcel() {
    import('XLSX').then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.showData);
          const workbook = {Sheets: { data: worksheet}, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
          });
          this.saveAsExcelFile(excelBuffer, "Kunden");
    });
  }

  //Save Data and Export it of EXCEL Data
  saveAsExcelFile(buffer: any, filName: string): void {
    import('file-saver').then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(
          data, filName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
        );
    });
  }

  GetMyId(myId: any){
   // console.log(myId);
  }

  selectProduct(prodId: any) {
   // console.log(prodId);
    const myReq = {id: prodId, status: '1'};
  //  console.log(myReq);
    this.http.post(this.HideStatusApi, myReq).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
  //    console.log(res);
      this.ngOnInit();
    })
  }

  onDateSelected(value: any) {
    let date = value.value.toLocaleDateString('de-DE', { day: 'numeric', month: 'long', year: 'numeric'});
  //  console.log(date);
  }


 }
