import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

 // private GetAllBenutzerApi = 'http://localhost/ApiGetaway/GetAllStatus.php';

  private GetAllBenutzerApi = '/ApiGetaway/GetAllStatus.php';

  @ViewChild('agGrid', {static: false}) agGrid: AgGridAngular | any ;
  public rowData: any = [];
  public defaultColDef: any;
  public gridApi: any;
  public gridColumnApi: any;
  public popupParent: any;
  public pivotPanelShow: any;
  public rowGroupPanelShow: any;

  columnDefs = [
    {headerName: "Kundenummer", field: "kundenummer", sortable: true, filter: true, checkboxSelection: true, width: 300},
    {headerName: "Vorname", field: "vorname", sortable: true, filter: true},
    {headerName: "Nachname", field: "nachname", sortable: true, filter: true},
    {headerName: "datum", field: "datum", sortable: true, filter: true, width: 250},
    {headerName: "status", field: "status", sortable: true, filter: true, width: 320},
    {headerName: "info", field: "info", sortable: true, filter: true, width: 550}
  ];

  constructor(private http: HttpClient)
  {
    this.popupParent = document.body;
    this.pivotPanelShow = 'always';
    this.rowGroupPanelShow = 'always';

      this.defaultColDef = {
        sortable: true,
        resizeble: true,
        width: 200,
        editable: true,

        filter: true
       // enableRowGroup: true,
       // enableValue: true,

      }

  }

  ngOnInit(): void {
    this.http.get(this.GetAllBenutzerApi).subscribe(data => {
      this.rowData = data;
      console.log(this.rowData);

    });


  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onChartCreated(event: any) {
    console.log('Created Chart with ID' + event.chartId, event);
  }

  onChartRangeSelectionChanged(event: any) {
    console.log(
      'Changed range selection of chart with ID ' + event.chartId,
      event
    );
  }

  onChartOptionsChanged(event: any) {
    console.log('Changed options of chart with ID ' + event.chartId, event);
  }

  onChartDestroyed(event: any) {
    console.log('Destroyed chart with ID ' + event.chartId, event);
  }





}
