import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MessageService} from 'primeng/api';
import {PrimeNGConfig} from 'primeng/api';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.css'],
  providers: [MessageService]
})
export class UserTableComponent implements OnInit {

// private GetAllBenutzerApi = 'http://localhost/ApiGetaway/GetAllBenutzer.php';
 private GetAllBenutzerApi = '/ApiGetaway/GetAllBenutzer.php';

  tableData: any;

  constructor
  (
    private messageService: MessageService,
    private primeConfig: PrimeNGConfig,
    private http: HttpClient,
    public dialogRef: MatDialogRef<UserTableComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData
  ) { }

  ngOnInit(): void {

      this.getAllUsers();
  }

  //Get All Users
  getAllUsers(): void {
    this.http.get(this.GetAllBenutzerApi).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      this.tableData = res;
      console.log(res);
    })
  }

}

interface DialogData {
  data: any;
}
