import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verwaltung',
  templateUrl: './verwaltung.component.html',
  styleUrls: ['./verwaltung.component.css']
})
export class VerwaltungComponent implements OnInit {

// private GetVerwaltungsApi = 'http://localhost/ApiGetaway/GetAllBenutzer.php';

  private GetVerwaltungsApi = '/ApiGetaway/GetAllBenutzer.php';

  public GetVerwaltungInfo: any;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {


}
}
