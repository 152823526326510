import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { CustomersComponent } from './pages/customers/customers.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DataViewComponent } from './pages/data-view/data-view.component';
import { EinloggenComponent } from './pages/einloggen/einloggen.component';
import { KundenLoginComponent } from './pages/kunden-login/kunden-login.component';
import { MitarbeiterLoginComponent } from './pages/mitarbeiter-login/mitarbeiter-login.component';
import { MydatasComponent } from './pages/mydatas/mydatas.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { NotesComponent } from './pages/notes/notes.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { RegistrierenComponent } from './pages/registrieren/registrieren.component';
import { StatusComponent } from './pages/status/status.component';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { UserEditComponent } from './pages/user-edit/user-edit.component';
import { VerwaltungComponent } from './pages/verwaltung/verwaltung.component';


const routes: Routes = [
  {path: 'mitarbeiter-login', component: MitarbeiterLoginComponent},
  {path: 'einloggen', component: EinloggenComponent},
  {path: 'registrieren', component: RegistrierenComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'UserDetails', component: UserDetailsComponent},
  {path: 'Kunden', component: CustomersComponent},
  {path: 'Overview', component: OverviewComponent},
  {path: 'Editieren', component: UserEditComponent},
  {path: 'Verwaltung', component: VerwaltungComponent},
  {path: 'notAuthorized', component: NotAuthorizedComponent},
  {path: 'KundenLogin', component: KundenLoginComponent},
  {path: 'Notes', component: NotesComponent},
  {path: 'Status', component: StatusComponent},
  {path: 'DataView', component: MydatasComponent},
  {path: '', component: EinloggenComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
