import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EinloggenComponent } from './pages/einloggen/einloggen.component';
import { RegistrierenComponent } from './pages/registrieren/registrieren.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import { CourseDialogComponent } from './components/course-dialog/course-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';

import { ErrorComponent } from './error/error.component';
import { LoginerrorComponent } from './loginerror/loginerror.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {AgGridModule} from 'ag-grid-angular';
import { KundenAlertSuccessComponent } from './components/kunden-alert-success/kunden-alert-success.component';

import {ToastModule} from 'primeng/toast';
import {RippleModule} from 'primeng/ripple';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { UserEditComponent } from './pages/user-edit/user-edit.component';
import { VerwaltungComponent } from './pages/verwaltung/verwaltung.component';
import { MitarbeiterLoginComponent } from './pages/mitarbeiter-login/mitarbeiter-login.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { AuthService } from './auth.service';
import {BlockUIModule} from 'primeng/blockui';
import { KundenLoginComponent } from './pages/kunden-login/kunden-login.component';
import { NotesComponent } from './pages/notes/notes.component';
import { StatusComponent } from './pages/status/status.component';
import {DataViewModule} from 'primeng/dataview';
import {CardModule} from 'primeng/card';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';

import { DatePipe } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import localeDe from "@angular/common/locales/de";
import { AddklisteComponent } from './components/addkliste/addkliste.component';
import { AddstatusComponent } from './components/addstatus/addstatus.component';
import { HidedataComponent } from './components/hidedata/hidedata.component';
import { MydatasComponent } from './pages/mydatas/mydatas.component';
import { AddVorgangComponent } from './components/add-vorgang/add-vorgang.component';
import { UserTableComponent } from './components/user-table/user-table.component';
registerLocaleData(localeDe, "de");



@NgModule({
  declarations: [
    AppComponent,
    EinloggenComponent,
    RegistrierenComponent,
    CourseDialogComponent,
    ErrorComponent,
    LoginerrorComponent,
    DashboardComponent,
    KundenAlertSuccessComponent,
    UserDetailsComponent,
    CustomersComponent,
    OverviewComponent,
    SidebarComponent,
    UserEditComponent,
    VerwaltungComponent,
    MitarbeiterLoginComponent,
    NotAuthorizedComponent,
    KundenLoginComponent,
    NotesComponent,
    StatusComponent,
    AddklisteComponent,
    AddstatusComponent,
    HidedataComponent,
    MydatasComponent,
    AddVorgangComponent,
    UserTableComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ClipboardModule,
    MatTooltipModule,
    TableModule,
    MatDatepickerModule,
    ButtonModule,
    AgGridModule,
    RippleModule,
    ToastModule,
    BlockUIModule,
    DataViewModule,
    CardModule,
    MatCardModule,
    MatExpansionModule,
    MatTableModule,
    MatTabsModule
  ],
  providers: [DatePipe, {provide: LOCALE_ID, useValue: 'de-DE'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
