import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AgGridAngular} from 'ag-grid-angular';


@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

//  private GetAllOverviewApi = 'http://localhost/ApiGetaway/GetAllDetails2.php';

 private GetAllOverviewApi = '/ApiGetaway/GetAllDetails2.php';

  @ViewChild('agGrid', {static: false}) agGrid: AgGridAngular | any;
  public rowData: any = [];
  public defaultColDef: any;
  public gridApi: any;
  public gridColumnApi: any;
  public popupParent: any;
  public pivotPanelShow: any;
  public rowGroupPanelShow: any;
  public sideBar: any;

  columnDefs = [
    {headerName: "K-N", field: "Kunde", sortable: true, filter: true, checkboxSelection: true, width: 300},
    {headerName: "Kunde", field: "Kunden", sortable: true, filter: true, width: 280},
    {headerName: "Bemerkung", field: "Bemerkung", sortable: true, filter: true},
    {headerName: "StVa + Fahrer", field: "Mitarbeiter", sortable: true, filter: true, width: 280},
    {headerName: "Datum", field: "Datum", sortable: true, filter: true, width: 230},
    {headerName: "Notizen", field: "notizen", sortable: true, filter: true, width: 350},
  ];
  constructor(private http: HttpClient)
  {
    this.popupParent = document.body;
    this.pivotPanelShow = 'always';
    this.rowGroupPanelShow = 'always';
    this.sideBar = 'filters';

      this.defaultColDef = {
        sortable: true,
        resizeble: true,
        width: 363,
        editable: true,
        filter: true
      }
  }

  ngOnInit(): void {
    this.http.get(this.GetAllOverviewApi).subscribe(data => {
      this.rowData = data;
    })
  }

  onBtnClick(e: any) {
    this.rowData = e.rowData;
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onChartCreated(event: any) {
    console.log('Created Chart with ID' + event.chartId, event);
  }

  onChartRangeSelectionChanged(event: any) {
    console.log(
      'Changed range selection of chart with ID ' + event.chartId,
      event
    );
  }

  onChartOptionsChanged(event: any) {
    console.log('Changed options of chart with ID ' + event.chartId, event);
  }

  onChartDestroyed(event: any) {
    console.log('Destroyed chart with ID ' + event.chartId, event);
  }

  onBtExport() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'filename_of_your_choice'
    };
    this.gridApi.exportDataAsCsv(params);
  }
}
