import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { LoginerrorComponent } from 'src/app/loginerror/loginerror.component';

@Component({
  selector: 'app-mitarbeiter-login',
  templateUrl: './mitarbeiter-login.component.html',
  styleUrls: ['./mitarbeiter-login.component.css']
})
export class MitarbeiterLoginComponent implements OnInit {

  @ViewChild('email') email: any;
  @ViewChild('pin') pin: any;

//  private LoginGetAwayApi = 'http://localhost/ApiGetaway/LogInDash.php';

  private LoginGetAwayApi = '/ApiGetaway/LogInDash.php';

  public UsrLogIn: any = {};

  isLoggedIn: boolean = false;

  blockedPanel: boolean = false;
  blockedDocument: boolean = false;


  constructor(
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.checkAuth();
  }

  LogInUsr(): void{
  //  console.log(this.email, this.pin);
    if(this.email === undefined || this.pin === undefined || this.email === '' || this.pin === '') {
      console.log('error');
      this.openDialog();
    } else {
      console.log('Running Server');
      const data = {
        email: this.email,
        pin: this.pin
      };
      this.UsrLogIn = data;
      this.http.post(this.LoginGetAwayApi, this.UsrLogIn).subscribe(async dataBack => {
        const res = JSON.parse(JSON.stringify(dataBack));
        if( res === 'Your Login success') {
          console.log('Login Success');
          this.authService.login();
          this.GotoDashboard();
        } else {
          if (res === 'Your Login Email or Password is invalid') {
            console.log('Error Connecting');
          }
        }
      });
    }
  }

  LogOut(){
    this.authService.logout();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginerrorComponent, {
      width: '500px',
      data: {}
    });
      dialogRef.afterClosed().subscribe(result => {
        console.log('Error');
      });
  }

  HashAddress(length: any) {
    let result =  [];
    let charachter =  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charachterLength = charachter.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(charachter.charAt(Math.floor(Math.random() * charachterLength)));
    }
    return result.join('');
  }

  GotoDashboard(): void {
    this.blockedDocument = true;
    setTimeout(() => {
      this.blockedDocument = false
    }, 3000);
    this.router.navigate(['/dashboard'], {queryParams: {user: this.HashAddress(12)}});
  }

}
