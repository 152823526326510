import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-kunden-alert-success',
  templateUrl: './kunden-alert-success.component.html',
  styleUrls: ['./kunden-alert-success.component.css']
})
export class KundenAlertSuccessComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<KundenAlertSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

interface DialogData {
  emails: any;
}
