import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-addstatus',
  templateUrl: './addstatus.component.html',
  styleUrls: ['./addstatus.component.css']
})
export class AddstatusComponent implements OnInit {

 // private PostSListeApi = 'http://localhost/ApiGetaway/PostSListe.php';
  private PostSListeApi = '/ApiGetaway/PostSListe.php';

  @ViewChild('status') status: any;

  public AllStatus: any;


  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<AddstatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  PostSListe(): void {
    const ReqData = { status: this.status}
    this.http.post(this.PostSListeApi, ReqData).subscribe(data =>{
      const res = JSON.parse(JSON.stringify(data));
      console.log(res);
      if (res.status === 'erfolgreich') {
        this.dialogRef.close();
      } else {
        console.log('Error, Please Check Connection Or API');
      }

    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

interface DialogData {
  status: any;
}
