import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import {MessageService} from 'primeng/api';
import {PrimeNGConfig} from 'primeng/api';


@Component({
  selector: 'app-kunden-login',
  templateUrl: './kunden-login.component.html',
  styleUrls: ['./kunden-login.component.css'],
  providers: [MessageService]
})
export class KundenLoginComponent implements OnInit {

  @ViewChild('kundenummer') kundenummer: any;
  @ViewChild('pin') pin: any;

// private LoginKundenGetAwayApi = 'http://localhost/ApiGetaway/LogInKunde.php';

 private LoginKundenGetAwayApi = '/ApiGetaway/LogInKunde.php';

  public KndLogIn: any = {};
  isLoggedIn: boolean = false;

  blockedPanel: boolean = false;
  blockedDocument: boolean = false;


  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private primeConfig: PrimeNGConfig
    ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.checkAuth();
  }

  LogInKunde(): void {
   // console.log(this.kundenummer, this.pin);
    if (this.kundenummer === undefined || this.pin === undefined) {
      console.log('Error!');
    } else {
      console.log('Running Server');
      const data = {
        kundenummer : this.kundenummer,
        pin: this.pin
      };
      this.KndLogIn = data;
      this.http.post(this.LoginKundenGetAwayApi, this.KndLogIn).subscribe(async datax => {
        const res  = JSON.parse(JSON.stringify(datax));
        // console.log(res);
        if (res === 'success') {
          this.authService.login();
          this.router.navigate(['registrieren'], {queryParams: { kundeData : this.kundenummer }});
        } else {
          console.log('error');
          this.errorDialog();
        }

      });
    }
  }

  errorDialog(): void{
    this.messageService.add({
      severity: 'error',
      summary: 'Fehler',
      detail: 'Ihre Kundenummer oder Ihr PIN-Code ist nicht korrekt. Bitte versuchen Sie nochmal.'
    })
  }

}
