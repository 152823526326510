import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { Kunden } from 'src/app/classes/kunden';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {PrimeNGConfig} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';




@Component({
  selector: 'app-mydatas',
  templateUrl: './mydatas.component.html',
  styleUrls: ['./mydatas.component.css'],
  providers: [MessageService, DatePipe]
})
export class MydatasComponent implements OnInit {

/*  private GetAllDatasApi = 'http://localhost/ApiGetaway/GetAllDetails.php';
  private GetAllKundenApi = 'http://localhost/ApiGetaway/GetAllKunden.php';
  private GetKundeAllViewApi = 'http://localhost/ApiGetaway/GetKundeAllView.php';
*/

  private GetAllDatasApi = '/ApiGetaway/GetAllDetails.php';
  private GetAllKundenApi = '/ApiGetaway/GetAllKunden.php';
  private GetKundeAllViewApi = '/ApiGetaway/GetKundeAllView.php';

  public ShowDatas : any;
  tableData : any;
  public ShowKunden : any;
  public reqData : any;
  panelOpenState = false;
  fakeDate?: any;

  //Kunden List Arrays
  public k_buero: any;
  public Schinkers_Moers: any;
  public Schinkers_Xanten: any;
  public Schinkers_Gel: any;
  public Flm: any;
  public breuer: any;
  public stva: any;
  public Mo_Rhg: any;
  public Jo_Cars: any;
  public Rebec: any;
  public Flg: any;
  public Vlm: any;
  public Mo_Moers: any;
  public Mc: any;
  public Minrath: any;
  public Lrm: any;
  public Gerstmann: any;
  public Funding : any;
  public Horst: any;
  public Abmeldungen: any;
  public Tommy_T_K: any;
  public Autohaus_Pullacher: any;
  public Klm: any;
  public Porsche_Zentrum: any;
  public Prager: any;
  public Autohaus_Klisch: any;
  public Stw_m_Baumann: any;
  public A_C: any;
  public Bulenda: any;

  public dataSource: any;
  public ELEMENT_DATA: any;


  @ViewChild('myKunde') myKunde: any;
  @ViewChild('datum_bnzh') datum_bnzh : any;




  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private primeConfig: PrimeNGConfig,
    private datePipe: DatePipe,
    public dialog: MatDialog
  ) {



  }

  ngOnInit(): void {

    this. GetAllKunden();

    const currentDate = this.fakeDate ? new Date(this.fakeDate) : new Date();
    const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1 < 10 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1}-${currentDate.getDate() < 10 ? "0" + currentDate.getDate() : currentDate.getDate()}`;
   // console.log('currentDate' , currentDate);
   // console.log('formattedDate', formattedDate);
  }

   //GET All Kunden and Push it into K.List.
   GetAllKunden(): void {
    this.http.get(this.GetAllKundenApi).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
      this.ShowKunden = res;
  //     console.log(this.ShowKunden);
    });
  }

  //GetKundeInfos
  getKundeInfo(): void {
    var datepipe = new DatePipe("de-DE");
    let DataViewDates = this.datePipe.transform(this.datum_bnzh, 'dd.MM.yyyy', 'de-DE');
    const resq = { Datum: DataViewDates }
    this.reqData = DataViewDates;

    // console.log(this.reqData);
    this.http.post(this.GetKundeAllViewApi, resq).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
      this.k_buero = res.filter((obj: any) => obj.Kunde === 'Büro');
      this.Schinkers_Moers = res.filter((obj: any) => obj.Kunde === 'Schnickers Moers');
      this.Schinkers_Xanten = res.filter((obj: any) => obj.Kunde === 'Schnickers Xanten');
      this.Schinkers_Gel = res.filter((obj: any) => obj.Kunde === 'Schnickers GEL');
      this.Flm = res.filter((obj: any) => obj.Kunde === 'FLM');
      this.breuer = res.filter((obj: any) => obj.Kunde === 'Breuer');
      this.stva = res.filter((obj: any) => obj.Kunde === 'Stva');
      this.Mo_Rhg = res.filter((obj: any) => obj.Kunde === 'MO.RHG');
      this.Jo_Cars = res.filter((obj: any) => obj.Kunde === 'JoCars');
      this.Rebec = res.filter((obj: any) => obj.Kunde === 'Rebac');
      this.Flg = res.filter((obj: any) => obj.Kunde === 'FLG');
      this.Vlm = res.filter((obj: any) => obj.Kunde === 'VLM');
      this.Mo_Moers = res.filter((obj: any) => obj.Kunde === 'MO.Moers');
      this.Mc = res.filter((obj: any) => obj.Kunde === 'MC');
      this.Minrath = res.filter((obj: any) => obj.Kunde === 'Minrath');
      this.Lrm = res.filter((obj: any) => obj.Kunde === 'LRM');
      this.Gerstmann = res.filter((obj: any) => obj.Kunde === 'Gerstmann');
      this.Funding = res.filter((obj: any) => obj.Kunde === 'Fündling');
      this.Horst = res.filter((obj: any) => obj.Kunde === 'Horst');
      this.Abmeldungen = res.filter((obj: any) => obj.Kunde === 'Abmeldungen');
      this.Tommy_T_K = res.filter((obj: any) => obj.Kunde === 'Tommy-T-K');
      this.Autohaus_Pullacher = res.filter((obj: any) => obj.Kunde === 'Autohaus Pullacher');
      this.Klm = res.filter((obj: any) => obj.Kunde === 'KLM');
      this.Porsche_Zentrum = res.filter((obj: any) => obj.Kunde === 'Porsche Zentrum');
      this.Prager = res.filter((obj: any) => obj.Kunde === 'Prager');
      this.Autohaus_Klisch = res.filter((obj: any) => obj.Kunde === 'Autohaus Klisch');
      this.Stw_m_Baumann = res.filter((obj: any) => obj.Kunde === 'Stw M. Baumann');
      this.A_C = res.filter((obj: any) => obj.Kunde === 'A & C');
    //  console.log('Result : ',this.k_buero, '\n', this.Rebec );
      this.ShowDatas = res;
    //  console.log('Show Datas : ', this.ShowDatas);


      //console.log(res);
      if (res.length == []) {
    //    console.log('Empty');
      } else {
     //   console.log('full');
      }
    })

  }

}






