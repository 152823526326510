import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-addkliste',
  templateUrl: './addkliste.component.html',
  styleUrls: ['./addkliste.component.css']
})
export class AddklisteComponent implements OnInit {

//  private PostKListeApi = 'http://localhost/ApiGetaway/PostKListe.php';
  private PostKListeApi = '/ApiGetaway/PostKListe.php';

  @ViewChild('kunden') kunden: any;
  @ViewChild('k_id') k_id: any;
  @ViewChild('datum') datum: any;

  TodayDate: any;
  newId: any;

  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<AddklisteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  )
  {

  }

  ngOnInit(): void {
    let today = new Date().toLocaleDateString();
    this.TodayDate = today;
    this.newId = this.RandomNumb(4);

  }

  //Add Kunden in K Liste
  PostKListe(): void {
    const ReqData = {
      k_id: this.newId,
      kunden: this.kunden,
      datum : this.TodayDate
    }
    console.log(ReqData);
    this.http.post(this.PostKListeApi, ReqData).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
      console.log(res);
      if (res.status === 'erfolgreich') {
        this.dialogRef.close();
      } else {
        console.log('Error, Please Check Connection Or API');
      }
    });
  }

  // Generate Random ID
  RandomNumb(length: any) {
    let result = [];
    let charachter = '0123456789';
    let charachterLength = charachter.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(charachter.charAt(Math.floor(Math.random() * charachterLength)));
    }
    return result.join('');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}



interface DialogData {
  k_id: any;
  kunden: any;
  datum: any;
}
