import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import {MessageService} from 'primeng/api';
import {PrimeNGConfig} from 'primeng/api';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
  providers: [MessageService]
})
export class UserDetailsComponent implements OnInit {

  //API GETAWAY ADDRESSES
 /* private GetAllKundenApi = 'http://localhost/ApiGetaway/GetAllDetails2.php';
  private GetSingleKundeApi =  'http://localhost/ApiGetaway/GetKundenInfo.php';
  private UpdateKundenInfoApi = 'http://localhost/ApiGetaway/UpdateKundenInfo.php';
  private DeleteKundeInfoApi = 'http://localhost/ApiGetaway/DeleteInfo.php'; */


    //API GETAWAY ADDRESSES
    private GetAllKundenApi = '/ApiGetaway/GetAllDetails2.php';
    private GetSingleKundeApi =  '/ApiGetaway/GetKundenInfo.php';
    private UpdateKundenInfoApi = '/ApiGetaway/UpdateKundenInfo.php';
    private DeleteKundeInfoApi = '/ApiGetaway/DeleteInfo.php';

  // ARRAYS
  public kundeListe: any;
  public id: any;
  public SingleKunde: any;
  public kundenId : any;

  constructor
  (
    private http: HttpClient,
    public formBuilder: FormBuilder,
    private messageService: MessageService,
    private primeConfig: PrimeNGConfig,
    private datePipe: DatePipe
  )
  {

    registerLocaleData(localeDe, 'de-DE', localeDeExtra);

  }

  ngOnInit(): void {
    //LOAD ALL DATA INTO TABLE
    this.http.get(this.GetAllKundenApi).subscribe(async data =>{
      this.kundeListe = data;
    });
    this.primeConfig.ripple = true;
  }

  // GET SINGLE CUSTOMER AND SHOW HIS INFORMATIONS
  test(id: any){
      this.http.post(this.GetSingleKundeApi, id).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      this.SingleKunde = res;
      console.log(this.SingleKunde);
    });
  }

  // DELETE SINGLE CUSTOMER BY ID
  deletePost(id: any) {
   this.http.post(this.DeleteKundeInfoApi, id).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      this.ShowDeleteMsg();
      this.ngOnInit();
    });
  }



  // UPDATE CUSTOMERS INFORMATIONS
  onSubmit(f: NgForm) {
    var datepipe = new DatePipe("de-DE");
   /*   let formatdate = datepipe.transform(f.value['datum'], 'dd-MM-yyyy');
      console.log(formatdate);
      f.value['datum']= formatdate; */

    // f.value['datum'] = this.datePipe.transform(f.value['datum'], 'dd-MM-yyyy');
   // console.log(f.value['datum']);
    this.http.post(this.UpdateKundenInfoApi, f.value).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      if (res['status'] === 'erfolglisch') {
        this.ShowSuccess();
        f.resetForm();
        this.ngOnInit();
      } else {
        this.ShowError();
      }
    });
  }


  //PRIMENG ERROR TOAST
  ShowError(){
    this.messageService.add({
      severity: 'error',
      summary: 'Fehler',
      detail: 'Bitte füllen Sie alle erforderlichen Felder aus'
    });
  }

  //PRIMENG SUCCESS TOAST
  ShowSuccess(){
    this.messageService.add({
      severity: 'success',
      summary: 'Erfolgreich',
      detail: 'Die Kundeninformationen wurden erfolgreich aktualisiert'
    });
  }

  //PRIMENG SUCCESS DELETE TOAST
  ShowDeleteMsg(){
    this.messageService.add({
      severity: 'warn',
      summary: 'Gelöscht',
      detail: 'Der Kunde wurde erfolgreich gelöscht'
    });
  }

}
