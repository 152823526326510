import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {AgGridAngular} from 'ag-grid-angular';



@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

//  private GetAllBenutzerApi = 'http://localhost/ApiGetaway/GetAllBenutzer.php';
   private GetAllBenutzerApi = '/ApiGetaway/GetAllBenutzer.php';

  @ViewChild('agGrid', {static: false}) agGrid: AgGridAngular | any ;
  public rowData: any = [];
  public defaultColDef: any;
  public gridApi: any;
  public gridColumnApi: any;
  public popupParent: any;
  public pivotPanelShow: any;
  public rowGroupPanelShow: any;

  columnDefs = [
    {headerName: "Kundenummer", field: "kundenummer", sortable: true, filter: true, checkboxSelection: true},
    {headerName: "Kunde", field: "kunde", sortable: true, filter: true},
    {headerName: "Vorname", field: "vorname", sortable: true, filter: true},
    {headerName: "Nachname", field: "nachname", sortable: true, filter: true},
    {headerName: "Email", field: "email", sortable: true, filter: true},
    {headerName: "Tel", field: "tel", sortable: true, filter: true},
    {headerName: "Pin", field: "pin", sortable: true, filter: true},
    {headerName: "Bemerkung", field: "bemerkung", sortable: true, filter: true},
    {headerName: "Datum", field: "datum", sortable: true, filter: true}
  ];

  constructor(private http: HttpClient)
  {
    this.popupParent = document.body;
    this.pivotPanelShow = 'always';
    this.rowGroupPanelShow = 'always';

      this.defaultColDef = {
        sortable: true,
        resizeble: true,
        width: 200,
        editable: true,

        filter: true
       // enableRowGroup: true,
       // enableValue: true,

      }

  }

  ngOnInit(): void {
    this.http.get(this.GetAllBenutzerApi).subscribe(data => {
      this.rowData = data;
    });


  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onChartCreated(event: any) {
    console.log('Created Chart with ID' + event.chartId, event);
  }

  onChartRangeSelectionChanged(event: any) {
    console.log(
      'Changed range selection of chart with ID ' + event.chartId,
      event
    );
  }

  onChartOptionsChanged(event: any) {
    console.log('Changed options of chart with ID ' + event.chartId, event);
  }

  onChartDestroyed(event: any) {
    console.log('Destroyed chart with ID ' + event.chartId, event);
  }





}
