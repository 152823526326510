import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, NgForm, Validators} from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseDialogComponent } from 'src/app/components/course-dialog/course-dialog.component';
import { ModalComponent } from '../../modal/modal.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ErrorComponent } from 'src/app/error/error.component';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AddVorgangComponent } from 'src/app/components/add-vorgang/add-vorgang.component';

@Component({
  selector: 'app-registrieren',
  templateUrl: './registrieren.component.html',
  styleUrls: ['./registrieren.component.css']
})
export class RegistrierenComponent implements OnInit {

  //public emails = 'aaaa';
  public genPass: any;
  public UserDetailsReg : any = {};

 /* private dataGetAwayApi = 'http://localhost:3000/reg';
  private pHpGetAwayApi = 'http://localhost/ApiGetaway/GetAllUsers.php';
  private CheckVersandStatus = 'http://localhost/ApiGetaway/GetVersandStatus.php';
  private  GetMyStatusApi  = 'http://localhost/ApiGetaway/GetMyStatus.php';
  private SearchUserPlateApi = 'http://localhost/ApiGetaway/SearchUserPlate.php';
  private GetUserInfoApi = 'http://localhost/ApiGetaway/GetUserInfos.php';
*/

  private pHpGetAwayApi = '/ApiGetaway/GetAllUsers.php';
  private CheckVersandStatus = '/ApiGetaway/GetVersandStatus.php';
  private  GetMyStatusApi  = '/ApiGetaway/GetMyStatus.php';
  private SearchUserPlateApi = '/ApiGetaway/SearchUserPlate.php';
  private GetUserInfoApi = '/ApiGetaway/GetUserInfos.php';


  @ViewChild('kundenummer') kundenummer: any;
  @ViewChild('search') search: any;
  @ViewChild('status') status: any;


  isDisabled = false;
  public MyStatus: any
  public MyData: any = {};
  public KundeData: any;
  isShown: boolean = false ;
  public ShwKd: any;
  public isDisableKd: boolean = false;
  public Erledigen: any;
  public StartUpData: any;
  public Mytest1: any;
  public Mytest2: any;
  public Mykunden: any;
  public searchInp: boolean = false;
  public searchBtn: boolean = false;
  public labelDis: boolean = true;


  constructor(
        public http: HttpClient,
        private fb: FormBuilder,
        public dialog: MatDialog,
        public route: Router,
        private router: ActivatedRoute
    )
    {

   }

  ngOnInit(): void {
    // Get Customer Number
    this.router.queryParams.subscribe( params => {
      this.MyData = params['kundeData'];
    });
    // Get Customer Information via Send Customer Number to Backend
    this.http.post(this.GetMyStatusApi, this.MyData).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      this.checkUserData();
      if (res.length == []) {
        console.error('Es gibt kein neuen Informationen');
        this.searchInp = true;
        this.searchBtn = true;
        this.labelDis = false;
      } else {
                  // Check if Customer Private or Company
      this.Mykunden = res[0].vorname;  // Check the Company Name and Get the Data
      console.log(this.Mykunden);
      this.KundeData = res.filter((obj: any) => obj.status !== 'Zulassung erledigt');
      if (this.KundeData.length == []) {   // Check if there are Data in Working Process
        this.KundeData = res.filter((obj: any) => obj.status == 'Zulassung erledigt');
      }
    }
    });

   /* this.http.post(this.GetMyStatusApi, this.MyData).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      this.KundeData = res.filter((obj: any) => obj.status !== 'Zulassung erledigt');
      this.Erledigen = res.filter((obj: any) => obj.status === 'Zulassung erledigt');
      console.log('Erledigen :' , this.Erledigen);
      console.log('Kunde Data  : ', this.KundeData);
      this.checkUserData();
      if (this.KundeData.length === 0 ) {
        this.toggleShow();
      }
    }) */
  }

  getStatusResult(): void {
    this.http.post(this.GetMyStatusApi, this.MyData).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      if (this.status === 'laufenden Vorgänge') {
        this.KundeData = res.filter((obj: any) => obj.status !== 'Zulassung erledigt');
        console.log('laufenden Vorgänge');
      } else if (this.status === 'Erledigte Vorgänge') {
        this.KundeData = res.filter((obj: any) => obj.status === 'Zulassung erledigt');
        console.log('Erledigte Vorgänge');
      } else if (res.length === []) {
        console.log('Nicht gefunden!')
      }
    });
  }

  CheckStatus(): void {
    if (this.kundenummer === undefined || this.kundenummer == '') {
      console.log('error');
    } else {
      const UserSync = { kundenummer: this.kundenummer};
      console.log(UserSync);
      this.http.post(this.CheckVersandStatus, UserSync).subscribe(async req => {
        const res = JSON.parse(JSON.stringify(req));
        console.log(res);
        this.MyStatus = res;

      });
    }
  }

  SearchAfterUserPlate(): void {
  console.log(this.search);
  this.http.post(this.SearchUserPlateApi, this.search).subscribe(data => {
  const res = JSON.parse(JSON.stringify(data));
  if (!res.length ) {
    this.openErrorDialog();
    console.error('Error');
  } else {
    this.KundeData = res;
  }
  console.log(res);
   })

  }


  checkUserData(): void {
 // console.log(this.MyData);
  const myReq =  {kundenummer : this.MyData}
  this.http.post(this.GetUserInfoApi, this.MyData).subscribe(data => {
    const res = JSON.parse(JSON.stringify(data));
  //  console.log('User Info  :', res);
    this.ShwKd = res.map((p: any) => p.kunde);
    console.log('Kunde :',this.ShwKd);
    if (this.ShwKd[0] === 'gewerbekunde') {
      console.log('true');
      this.isDisableKd = false;
    } else {
      this.isDisableKd = true;
    }
  })
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '500px',
      data: {}
    });
       dialogRef.afterClosed().subscribe(result => {
         console.log('Erfoglisch');
    });
  }

  openErrorDialog(): void {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '500px',
      data: {}
    });
      dialogRef.afterClosed().subscribe(result => {
        console.log('Error');
      });
  }

  toggleShow() {
    this.isShown = ! this.isShown;
    }

    OpenVorgangDialog(): void {
      this.Mytest1 = 'test';
      this.Mytest2 = 'test2';
      const dialogRef = this.dialog.open(AddVorgangComponent, {
        width: '800px',
        data: {
            result: this.Mykunden
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('WebApp Restarted');
      });
    }



}
