import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MessageService} from 'primeng/api';
import {PrimeNGConfig} from 'primeng/api';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm} from '@angular/forms';


@Component({
  selector: 'app-add-vorgang',
  templateUrl: './add-vorgang.component.html',
  styleUrls: ['./add-vorgang.component.css'],
  providers: [MessageService, DatePipe]
})
export class AddVorgangComponent implements OnInit {

  private GetAllKundenApi = '/ApiGetaway/GetAllKunden.php';
  private PostCustomersApi = '/ApiGetaway/PostCustomers.php';

 /* private GetAllKundenApi = 'http://localhost/ApiGetaway/GetAllKunden.php';
  private PostCustomersApi = 'http://localhost/ApiGetaway/PostCustomers.php';
*/
  @ViewChild('kunde') kunde: any;
  @ViewChild('datum') datum: any;
  @ViewChild('kunden') kunden : any;
  @ViewChild('bemerkung') bemerkung : any;
  @ViewChild('mitarbeiter') mitarbeiter: any;
  @ViewChild('notizen') notizen: any

  public ShowKunden : any;
  public TagesPlanHin: any;
  public FilKunde: any;
  public txKunde: any;



  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private primeConfig: PrimeNGConfig,
    private datepipe: DatePipe,
    public dialog: MatDialog,
    private http: HttpClient,
    public dialogRef: MatDialogRef<AddVorgangComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.GetAllKunden();
    this.txKunde = this.data['result'];
    console.log('hheee',this.txKunde);
  }

    //GET All Kunden and Push it into K.List.
    GetAllKunden(): void {
      this.http.get(this.GetAllKundenApi).subscribe(data => {
        const res = JSON.parse(JSON.stringify(data));
       // console.log('TESYYYY',res);
        // this.ShowKunden = res.filter((obj: any) => obj.kunden == this.txKunde);
        this.ShowKunden = this.txKunde;
      });
    }

    PostVorgang(f: NgForm): void {
      console.log(this.kunde, this.datum, this.kunden, this.bemerkung, this.notizen, this.mitarbeiter);
      if (this.kunde === undefined || this.datum === undefined || this.kunden === undefined || this.bemerkung === undefined || this.mitarbeiter === undefined ) {
            this.ShowError();
            console.error('Error');
          } else {

           var deDate = new Date(this.datum);
           let formatdate = deDate.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit'});

           const UserInputs = {
             kunde: this.kunde,
             datum: this.datum = formatdate,
             kunden: this.kunden,
             bemerkung: this.bemerkung,
             notizen: this.notizen,
             mitarbeiter: this.mitarbeiter
           }
          console.log(UserInputs);
             this.TagesPlanHin = UserInputs;
             this.http.post(this.PostCustomersApi, this.TagesPlanHin).subscribe(async data => {
               const res = JSON.parse(JSON.stringify(data));
               console.info(res);
               this.showSuccess();
               f.resetForm();
               this.ngOnInit();
             });
          }
    }

    EraseCustomersForm(f: NgForm): void {
      f.resetForm()
    }

      //PrimeNg Success Toast
  showSuccess() {
    this.messageService.add({
      severity:'success',
      summary: 'Erfolgreich',
      detail: 'Ihre Daten wurden erfolgreich registriert'
    });
  }

  //PrimeNg Error Toast
  ShowError(){
    this.messageService.add({
      severity: 'error',
      summary: 'Fehler',
      detail: 'Bitte füllen Sie alle erforderlichen Felder aus'
    });
  }



}

interface DialogData {
  map: any;
  result: any;
  Mytest1: any;
  Mytest2: any;
  data: any;
}
