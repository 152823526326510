import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  HashAddress(length: any) {
    let result = [];
    let charachter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charachterLength = charachter.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(charachter.charAt(Math.floor(Math.random() * charachterLength)));
    }
    return result.join('');
  }

  GotoDashboard(): void {
    this.router.navigate(['/dashboard'], {queryParams: {User: this.HashAddress(12)}});
  }

  GotoUserDetails(): void {
    this.router.navigate(['/UserDetails'], {queryParams: {Infos: this.HashAddress(12)}});
  }

  GotoOverview(): void {
    this.router.navigate(['/Overview'], {queryParams: {Sicht: this.HashAddress(12)}});
  }

  GotoKunden(): void {
    this.router.navigate(['/Kunden'], {queryParams: {Custom: this.HashAddress(12)}});
  }

  GotoEditieren(): void {
    this.router.navigate(['/Editieren'], {queryParams: {UsrEdit: this.HashAddress(12)}});
  }

  GotoNotes(): void {
    this.router.navigate(['/Notes'], {queryParams: {MyNotes: this.HashAddress(12)}});
  }

  GotoStatuss(): void {
    this.router.navigate(['/Status'], {queryParams: {Custom: this.HashAddress(12)}});
  }

  GotoDataView(): void {
    this.router.navigate(['/DataView'], {queryParams: {View: this.HashAddress(12)}});
  }

}
