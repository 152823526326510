import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import {MessageService} from 'primeng/api';
import {PrimeNGConfig} from 'primeng/api';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css'],
  providers: [MessageService, DatePipe]
})
export class StatusComponent implements OnInit {

  //API GETAWAY ADDRESSES
 /* private GetUserStatusApi = 'http://localhost/ApiGetaway/GetUserStatus.php';
  private DeleteSingleStatusApi = 'http://localhost/ApiGetaway/DeleteSingleStatus.php';
  private UpdateSingleStatusApi = 'http://localhost/ApiGetaway/UpdateUserStatus.php';
  private GetUserSingleStatusApi = 'http://localhost/ApiGetaway/GetUserSingleStatus.php';
  private GetAllStatusListeApi = 'http://localhost/ApiGetaway/GetAllStatusList.php';
*/
  //API GETAWAY ADDRESSES
  private GetUserStatusApi = '/ApiGetaway/GetUserStatus.php';
  private DeleteSingleStatusApi = '/ApiGetaway/DeleteSingleStatus.php';
  private UpdateSingleStatusApi = '/ApiGetaway/UpdateUserStatus.php';
  private GetUserSingleStatusApi = '/ApiGetaway/GetUserSingleStatus.php';
  private GetAllStatusListeApi = '/ApiGetaway/GetAllStatusList.php';

  @ViewChild('status') status : any;

  //ARRAYS
  public UserStatus: any;
  public SingleStatus: any;
  public test: any;
  public AllStatus: any;

  constructor
  (
    private http: HttpClient,
    public formBuilder: FormBuilder,
    private messageService: MessageService,
    private primeConfig: PrimeNGConfig,
    private datePipe: DatePipe

  ) {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    this.test = localeDe;
  }

  ngOnInit(): void {
  //LOAD ALL DATA INTO TABLE
  this.http.get(this.GetUserStatusApi).subscribe(async data => {
    const res = JSON.parse(JSON.stringify(data));
    this.UserStatus = res.filter((obj: any) => obj.status !== 'Zulassung erledigt');
    console.log('Load Data',this.UserStatus);

  });
  this.primeConfig.ripple = true;
  this.GetAllStatus();
  }

  //Load Success and Not Success Reports
  getMySelectetdRes(): void {
    this.http.get(this.GetUserStatusApi).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      if (this.status === 'laufenden Vorgänge') {
        this.UserStatus = res.filter((obj: any) => obj.status !== 'Zulassung erledigt');
        console.log('laufende Vorgange');
      } else if (this.status === 'Erledigte Vorgänge') {
        this.UserStatus = res.filter((obj: any) => obj.status === 'Zulassung erledigt');
        console.log('Zulassung erledigt');
      }
    });
  }

  //GET SINGLE USER STATUS
  GetStatus(id: any) {
    console.log(id);
    this.http.post(this.GetUserSingleStatusApi, id).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      this.SingleStatus = res;
      console.log(res);
    });
  }

  //DELETE SINGLE STATUS BY ID
  DeleteStatus(id: any) {
   console.log(id);
    this.http.post(this.DeleteSingleStatusApi, id).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      this.ShowDeleteMsg();
      this.ngOnInit();
    });
  }

   //Get All Status From Database and Put it in List
   GetAllStatus(): void {
    this.http.get(this.GetAllStatusListeApi).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
      console.log(res);
      this.AllStatus = res;
     //console.log(this.AllStatus);
    })
  }

  //UPDATE USER STATUS
  onSubmit(f: NgForm) {
  //  f.value['datum'] = this.datePipe.transform(f.value['datum'], 'dd-MM-yyyy');
 //   console.log(f.value);

 //   var datepipe = new DatePipe("de-DE");
 //   let formatdate = datepipe.transform(f.value['datum'], 'dd.MM.yyyy');
 //   var deDate = new Date(f.value['datum']);
 //   let formatdate = deDate.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit'});
 //   console.log(formatdate);
 //   f.value['datum'] = formatdate;

    this.http.post(this.UpdateSingleStatusApi, f.value).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      if (res['status'] === 'erfolglisch') {
        this.ShowSuccess();
        f.resetForm();
        this.ngOnInit();
      } else {
        this.ShowError();
      }
    });
  }

  //PRIMJENG ERROR TOAST
  ShowError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Fehler',
      detail: 'Bitte füllen Sie alle erforderlichen Felder aus'
    });
  }

  //PRIMENG SUCCESS TOAST
  ShowSuccess(){
    this.messageService.add({
      severity: 'success',
      summary: 'Erfolgreich',
      detail: 'Die Kundeninformationen wurden erfolgreich aktualisiert'
    });
  }

  //PRIMENG SUCCESS DELETE TOAST
  ShowDeleteMsg(){
    this.messageService.add({
      severity: 'warn',
      summary: 'Gelöscht',
      detail: 'Der Kunde wurde erfolgreich gelöscht'
    });
  }

}
