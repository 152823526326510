import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginerrorComponent } from 'src/app/loginerror/loginerror.component';

@Component({
  selector: 'app-einloggen',
  templateUrl: './einloggen.component.html',
  styleUrls: ['./einloggen.component.css']
})
export class EinloggenComponent implements OnInit {

  @ViewChild('email') email: any;
  @ViewChild('kundenummer') kundenummer: any;

 /* private LoginGetAwayApi = 'http://localhost/ApiGetaway/LogInDash.php';
  private CheckVersandStatus = 'http://localhost/ApiGetaway/GetVersandStatus.php';
  private TestConnectionApi = 'http://localhost/api/test.php';
*/

  private LoginGetAwayApi = '/ApiGetaway/LogInDash.php';
  private CheckVersandStatus = '/ApiGetaway/GetVersandStatus.php';


  public UsrLogIn : any = {};

  constructor(
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
 /*   this.http.get(this.TestConnectionApi).subscribe(async data => {
      const res = JSON.parse(JSON.stringify(data));
      console.log('Test Connection', res);
    }) */
  }

  CheckStatus(f: NgForm): void {
    if (this.kundenummer === undefined || this.kundenummer == '') {
    //  console.log('error');
    } else {
      const UserSync = { kundenummer: this.kundenummer};
      this.http.post(this.CheckVersandStatus, f.value).subscribe(async req => {
        const res = JSON.parse(JSON.stringify(req));

      });
    }
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(LoginerrorComponent, {
      width: '500px',
      data: {}
    });
      dialogRef.afterClosed().subscribe(result => {
        console.log('Error')
      })
  }

  GoToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

}
