import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MessageService} from 'primeng/api';
import {PrimeNGConfig} from 'primeng/api';


@Component({
  selector: 'app-hidedata',
  templateUrl: './hidedata.component.html',
  styleUrls: ['./hidedata.component.css'],
  providers: [MessageService]
})
export class HidedataComponent implements OnInit {

 // private HideDataApi = 'http://localhost/ApiGetaway/GetHideData.php';
  private HideDataApi = '/ApiGetaway/GetHideData.php';
  tableData : any;


  constructor
  (
    private messageService: MessageService,
    private primeConfig: PrimeNGConfig,
    private http: HttpClient,
    public dialogRef: MatDialogRef<HidedataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.primeConfig.ripple = true;
    this.http.get(this.HideDataApi).subscribe(data => {
      const res = JSON.parse(JSON.stringify(data));
      this.tableData = res;
      console.log(res);
    });
  }

}


interface DialogData {
  data : any;
}
